<template>
	<div>
		<div class="page-section">
			<div class="page-section__inner gr gr--wrap">
				<div class="col col--1@tablet hide show@tablet"></div>

				<div class="col col--12 col--10@tablet page-section__card">
					<div v-html="content"></div>
				</div>
				<div class="col col--1@tablet hide show@tablet"></div>
			</div>
		</div>
	</div>
</template>

<script>
import content from '@/mixins/content';
export default {
	name: 'AboutPage',
	mixins: [content],
	created() {
		this.fetchContent('about-us');
	}
};
</script>
